export type SttResponseObject = {
    error?: {
        code: number
        message: string
    }
    results?: {
        alternatives: {
            transcript: string
            confidence: number
        }[]
        languageCode: string // default 'en-US'
        resultEndTime: string
    }[]
}

// Convert raw JSON string to formatted string
export function convertRawSttToReadableString(rawSttString: string): string {
    const sttResponseObject = JSON.parse(rawSttString) as SttResponseObject
    if (Object.keys(sttResponseObject).length === 0) {
        throw new Error(
            'Transcription file is empty. This usually means no transcribable audio was detected or one of the files was corrupted.'
        )
    } else if (sttResponseObject.error?.code) {
        throw new Error(sttResponseObject.error.message)
    }
    const results = sttResponseObject.results ?? []
    const outputArray: string[] = []
    results.forEach((r) => {
        const outputLine = `${r.resultEndTime}: ${r.alternatives[0].transcript}`
        outputArray.push(outputLine)
    })
    return outputArray.join('\n')
}
