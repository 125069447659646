import { readonly, ref, Ref } from 'vue'

import { firestore, serverTimestamp } from '@/firebase-config'

import useLevels from '@/composables/global/use-levels'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { docData, generateDocId, getCollectionDocs } from '@/utils/firestore'

import {
    voiceResponseQuestions,
    VoiceResponseQuestionData,
} from '@/data/voice-response-questions'

import { VoiceResponse } from '@/models/users/voice-response'

const debug = false

const responsesData: Ref<
    {
        level: {
            id: string
            num: number
            title: string
            icon?: string
        }
        questions: {
            question: VoiceResponseQuestionData
            responses: VoiceResponse[]
        }[]
    }[]
> = ref([])

const currentPlayingVoiceResponseId = ref<string | undefined>(undefined)

export default function () {
    const { viewer } = useViewer()

    const { levels } = useLevels()

    async function fetchAllVoiceResponsesByLevel() {
        await Promise.all(
            levels.value
                .slice() // Convert to mutable array
                .sort((a, b) => a.num - b.num)
                .map(async (level) => {
                    const levelQuestions = voiceResponseQuestions.filter(
                        (question) => question.moduleNumber === level.num
                    )
                    const questions: {
                        question: VoiceResponseQuestionData
                        responses: VoiceResponse[]
                    }[] = []
                    await Promise.all(
                        levelQuestions.map(async (question) => {
                            const responses = await fetchVoiceResponses(
                                question.id
                            )
                            if (responses.length > 0)
                                questions.push({
                                    question,
                                    responses: responses.sort(
                                        (a, b) => b.num - a.num
                                    ),
                                })
                        })
                    )
                    if (questions.length > 0)
                        responsesData.value.push({
                            level: {
                                id: level.id,
                                num: level.num,
                                title: level.title,
                                icon: level.icon,
                            },
                            questions,
                        })
                })
        )
    }

    async function fetchVoiceResponse(questionId: string) {
        logger(debug, questionId)

        if (viewer.value?.id === undefined) return undefined

        const responseSnapshot = await firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('voice-response')
            .where('question', '==', questionId)
            .orderBy('createdAt')
            .limitToLast(1)
            .get()

        if (responseSnapshot.empty) {
            return undefined
        } else {
            return docData<VoiceResponse>(responseSnapshot.docs[0])
        }
    }
    async function fetchVoiceResponses(questionId: string, userId?: string) {
        logger(debug, questionId)
        const user = userId ?? viewer.value?.id
        if (user === undefined) return []

        const responseSnapshot = await firestore
            .collection('users')
            .doc(user)
            .collection('voice-response')
            .where('question', '==', questionId)
            .orderBy('createdAt')
            .get()

        return getCollectionDocs<VoiceResponse>(responseSnapshot)
    }

    async function addVoiceResponse(
        questionId: string,
        response: string,
        num: number,
        duration: number
    ) {
        logger(debug, questionId, response)
        if (viewer.value?.id === undefined) return

        const voiceResponseCollectionRef = firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('voice-response')
        const id = generateDocId(voiceResponseCollectionRef)

        const responseRef = voiceResponseCollectionRef.doc(id)

        const batch = firestore.batch()
        batch.set(responseRef, {
            id,
            response,
            question: questionId,
            num,
            duration,
            createdAt: serverTimestamp(),
        })

        await batch.commit()
        return id
    }

    async function updateGivenName(voiceId: string, givenName: string) {
        logger(debug, voiceId, givenName)
        if (viewer.value?.id === undefined) return

        await firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('voice-response')
            .doc(voiceId)
            .update({
                givenName,
            })

        // Update responsesData
        responsesData.value.map((level) => {
            level.questions.map((question) => {
                question.responses.map((response) => {
                    if (response.id === voiceId) {
                        response.givenName = givenName
                    }
                })
            })
        })
    }

    async function deleteVoiceResponse(voiceId: string) {
        logger(debug, voiceId)
        if (viewer.value?.id === undefined) return

        await firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('voice-response')
            .doc(voiceId)
            .delete()

        // Remove from responsesData and remove level if no more responses
        responsesData.value = responsesData.value.filter((level) => {
            level.questions = level.questions.filter((question) => {
                question.responses = question.responses.filter(
                    (response) => response.id !== voiceId
                )
                return question.responses.length > 0
            })
            return level.questions.length > 0
        })
    }

    function deinitVoiceResponses() {
        responsesData.value = []
    }

    function setCurrentPlayingVoiceResponse(voiceId: string) {
        currentPlayingVoiceResponseId.value = voiceId
    }

    return {
        responsesData: readonly(responsesData),
        currentPlayingVoiceResponseId: readonly(currentPlayingVoiceResponseId),

        addVoiceResponse,
        deleteVoiceResponse,
        deinitVoiceResponses,
        fetchAllVoiceResponsesByLevel,
        fetchVoiceResponse,
        fetchVoiceResponses,
        setCurrentPlayingVoiceResponse,
        updateGivenName,
    }
}
