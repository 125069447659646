import { modalController } from '@ionic/vue'
import { computed, readonly, ref, Ref, watch } from 'vue'

import {
    firestore,
    FirestoreCollectionRef,
    FirestoreWriteBatch,
    serverTimestamp,
    FirestoreQuerySnapshot,
} from '@/firebase-config'
import Router, { hasEditHash, EDIT_HASH } from '@/router'

import SibtimeMomentModal from '@/components/SibtimeMomentModal.vue'

import useCourses from '@/composables/global/use-courses'
import useLevelPageElements from '@/composables/global/use-level-page-elements'
import useLevelPageQuizMedia from '@/composables/global/use-level-page-quiz-media'
import useLevels from '@/composables/global/use-levels'
import useQuiz from '@/composables/global/use-quiz'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import {
    cleanObjForFirestore,
    docData,
    getCollectionDocs,
    shiftDocNums,
} from '@/utils/firestore'

import { Level } from '@/models/courses/level'
import { Page } from '@/models/courses/levels/page'

/*
 * Exposes ref for current level page loaded and helper functions
 * Helps organize course navigation
 * First loaded when opening a level
 */

const debug = false

// State
const cachedPage: Ref<Page | undefined> = ref()
const branchRootPage: Ref<Page | undefined> = ref()
const failBranchInitialPage: Ref<Page | undefined> = ref()
const passBranchInitialPage: Ref<Page | undefined> = ref()

export default function (levelId?: string) {
    const { activeCourse } = useCourses()
    const { levels } = useLevels()
    const { viewer } = useViewer()

    function isValidBranchPage(page?: Page) {
        if (page === undefined) {
            return (
                cachedPage.value?.inBranch !== undefined &&
                cachedPage.value?.branchRoot !== undefined &&
                cachedPage.value?.branchNum !== undefined
            )
        } else {
            return (
                page?.inBranch !== undefined &&
                page?.branchRoot !== undefined &&
                page?.branchNum !== undefined
            )
        }
    }

    const isBranchPage = computed(() => isValidBranchPage())

    const isPageLocked = computed(() => {
        if (!cachedPage.value?.lockOnFail) return false
        if (!viewer.value?.lockedPage?.pageId) return false
        return viewer.value.lockedPage.pageId === cachedPage.value.id
    })

    let pagesCollection: FirestoreCollectionRef | undefined = undefined
    function setLevel(levelId: string) {
        logger(debug, levelId)
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        pagesCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('levels')
            .doc(levelId)
            .collection('pages')
    }

    if (activeCourse.value?.id !== undefined && levelId !== undefined) {
        setLevel(levelId)
    }

    // Fetching

    async function fetchPage(id: string) {
        logger(debug, id)
        if (pagesCollection === undefined) throw 'No level specified'

        const pageSnapshot = await pagesCollection.doc(id).get()
        if (pageSnapshot.exists) {
            return docData<Page>(pageSnapshot)
        } else {
            return undefined
        }
    }

    async function fetchPreviousRootPages(pageNum: number) {
        logger(debug, pageNum)
        if (pagesCollection === undefined) throw 'No level specified'

        const querySnapshot = await pagesCollection
            .where('num', '<', pageNum)
            .where('inBranch', '==', false)
            .get()

        if (querySnapshot.empty) {
            logger(debug, 'No previous root pages found', pageNum)
            return []
        } else {
            const pagesFound = getCollectionDocs<Page>(querySnapshot)
            return pagesFound
        }
    }

    async function fetchNumPage(pageNum: number) {
        logger(debug, pageNum)
        if (pagesCollection === undefined) throw 'No level specified'

        const querySnapshot = await pagesCollection
            .where('num', '==', pageNum)
            .where('inBranch', '==', false)
            .get()

        if (querySnapshot.empty) {
            logger(debug, 'Page num not found', pageNum)
            return undefined
        } else {
            const pageFound = docData<Page>(querySnapshot.docs[0])
            return pageFound
        }
    }

    async function fetchFirstBranchPage(inPassBranch: boolean) {
        logger(debug, inPassBranch)
        if (pagesCollection === undefined) throw 'No level specified'

        if (!cachedPage.value || isBranchPage.value) {
            return undefined
        }

        const branchRoot = cachedPage.value.id

        const querySnapshot = await pagesCollection
            .where('num', '==', 1)
            .where('inBranch', '==', true)
            .where('branchRoot', '==', branchRoot)
            .where('inPassBranch', '==', inPassBranch)
            .get()

        if (querySnapshot.empty) {
            logger(debug, 'No initial branch page found', inPassBranch)
            return undefined
        } else {
            const pageFound = docData<Page>(querySnapshot.docs[0])
            return pageFound
        }
    }

    async function fetchBranchNumPage(
        pageNum: number,
        branchRoot: string,
        branchNum: number
    ) {
        logger(debug, pageNum, branchRoot, branchNum)
        if (pagesCollection === undefined) throw 'No level specified'

        const querySnapshot = await pagesCollection
            .where('num', '==', pageNum)
            .where('inBranch', '==', true)
            .where('branchRoot', '==', branchRoot)
            .where('branchNum', '==', branchNum)
            .get()

        if (querySnapshot.empty) {
            logger(debug, 'Page num not found', pageNum)
            return undefined
        } else {
            const pageFound = docData<Page>(querySnapshot.docs[0])
            return pageFound
        }
    }

    async function fetchFinalPage() {
        logger(debug)
        if (pagesCollection === undefined) throw 'No level specified'

        const querySnapshot = await pagesCollection
            .orderBy('num')
            .limitToLast(1)
            .get()
        if (querySnapshot.empty) {
            logger(debug, 'Final page not found')
            return undefined
        } else {
            return docData<Page>(querySnapshot.docs[0])
        }
    }

    async function fetchFinalBranchPage(branchPage?: {
        branchRoot: string
        branchNum: number
    }) {
        logger(debug)
        if (pagesCollection === undefined) throw 'No level specified'

        let branchRoot = undefined
        let branchNum = undefined

        if (branchPage) {
            branchRoot = branchPage.branchRoot
            branchNum = branchPage.branchNum
        } else {
            branchRoot = cachedPage.value?.branchRoot
            branchNum = cachedPage.value?.branchNum
        }

        if (branchRoot === undefined || branchNum === undefined) {
            return undefined
        }

        const querySnapshot = await pagesCollection
            .where('inBranch', '==', true)
            .where('branchRoot', '==', branchRoot)
            .where('branchNum', '==', branchNum)
            .orderBy('num')
            .limitToLast(1)
            .get()

        if (querySnapshot.empty) {
            logger(debug, 'Final branch page not found')
            return undefined
        } else {
            return docData<Page>(querySnapshot.docs[0])
        }
    }

    /**
     * Calculate the number of completed root pages
     */
    async function getProgressedPageCount(pageId?: string) {
        logger(debug, pageId)

        let progressedPageCount = 0

        const progressedPage = pageId
            ? await fetchPage(pageId)
            : cachedPage.value

        if (!progressedPage) {
            return progressedPageCount
        }

        if (isValidBranchPage(progressedPage)) {
            const branchRootPage = await fetchPage(
                progressedPage.branchRoot as string
            )
            if (branchRootPage === undefined) throw 'No branch root found'

            progressedPageCount = branchRootPage.num
        } else {
            progressedPageCount = progressedPage.num
        }

        return progressedPageCount
    }

    async function getTotalBranchPageCount() {
        logger(debug)
        if (!isBranchPage.value) throw 'Not a branch page'

        const finalBranchPage = await fetchFinalBranchPage()
        return finalBranchPage?.num ?? 1
    }

    async function getTotalRootPageCount() {
        logger(debug)

        const finalPage = await fetchFinalPage()
        return finalPage?.num ?? 1
    }

    async function getLevelPages(options?: { includeBranchPages?: boolean }) {
        logger(debug)
        if (pagesCollection === undefined) {
            throw 'No level specified'
        } else if (activeCourse.value === undefined) {
            throw 'No active course'
        }

        let titledPageData: Page[] = []

        let levelPagesSnapshot: FirestoreQuerySnapshot

        if (options?.includeBranchPages === false) {
            levelPagesSnapshot = await pagesCollection
                .where('inBranch', '==', false)
                .orderBy('num')
                .get()
        } else {
            levelPagesSnapshot = await pagesCollection.orderBy('num').get()
        }

        const pagesData = getCollectionDocs<Page>(levelPagesSnapshot)
        titledPageData = pagesData.map((levelPage) => {
            if (levelPage.title === '') {
                levelPage.title = 'No Title'
            }
            return levelPage
        })
        return titledPageData
    }

    // Loading

    async function loadPage(id: string) {
        logger(debug, id)
        if (cachedPage.value && cachedPage.value.id === id) {
            logger(debug, 'Page was preloaded')
            return Promise.resolve()
        }

        cachedPage.value = await fetchPage(id)
    }

    async function loadFirstPage() {
        logger(debug)
        cachedPage.value = await fetchNumPage(1)
    }

    async function loadBranchPages() {
        passBranchInitialPage.value = await fetchFirstBranchPage(true)
        failBranchInitialPage.value = await fetchFirstBranchPage(false)
    }

    async function loadBranchRootPage() {
        logger(debug)
        const noCachedPage = cachedPage.value === undefined
        const notBranchPage = !isBranchPage.value

        if (noCachedPage || notBranchPage) {
            branchRootPage.value = undefined
            return
        }
        if (cachedPage.value?.branchRoot)
            branchRootPage.value = await fetchPage(cachedPage.value.branchRoot)
    }
    // Routing

    function navToLevels() {
        logger(debug)
        return Router.push({ name: 'LevelList' })
    }

    /**
     * Navigates to a specified page within the application.
     * This method should be followed by the use of the 'loadPage' function for complete page loading or
     * (Recommended) consider using 'routeNav' for alternative navigation.
     */
    function navToPage(pageId: string) {
        logger(debug, pageId)
        if (pagesCollection?.parent?.id === undefined) {
            throw 'No level specified'
        }

        return Router.push({
            name: 'LevelPage',
            params: {
                levelId: pagesCollection.parent.id,
                pageId: pageId,
            },
            hash: hasEditHash.value ? EDIT_HASH : undefined,
        })
    }

    function navToReachableNextSteps() {
        logger(debug)

        const level = levels.value.find((l) => l.id === levelId) as Level

        return Router.push({
            name: 'ReachableNextStepPage',
            params: {
                levelNum: level.num,
            },
        })
    }

    async function routeNav(targetPage: Page | undefined) {
        logger(debug, targetPage)
        const level = levels.value.find((l) => l.id === levelId) as Level

        if (targetPage === undefined) {
            if (activeCourse.value?.options?.includes('nextSteps')) {
                return navToReachableNextSteps()
            } else if (
                activeCourse.value?.options?.includes('sibTimeMoments') &&
                viewer.value?.sibTimeMoment?.triggerTime === undefined &&
                level.num !== 1
            ) {
                const sibtimeMomentForm = await modalController.create({
                    id: 'sibtimeMoment',
                    component: SibtimeMomentModal,
                    backdropDismiss: false,
                    cssClass: ['stack-modal', 'medium-size-modal'],
                })
                sibtimeMomentForm.present()
            } else {
                return navToLevels()
            }
        } else {
            const navError = await navToPage(targetPage.id)
            if (navError?.name === undefined) cachedPage.value = targetPage
        }
    }

    async function navForwardBranchPage() {
        logger(debug)

        if (!cachedPage.value) {
            logger(debug, 'cached page undefined')
            return navToLevels() // Current page must be set already
        }

        const branchRoot = cachedPage.value.branchRoot as string
        const branchNum = cachedPage.value.branchNum as number
        const nextBranchPageNum = cachedPage.value.num + 1
        const nextBranchPage = await fetchBranchNumPage(
            nextBranchPageNum,
            branchRoot,
            branchNum
        )
        // nav to next available branch page
        if (nextBranchPage) {
            return routeNav(nextBranchPage)
        }
        const branchRootPage = await fetchPage(
            cachedPage.value.branchRoot as string
        )
        // nav to levels if no next branch page or root
        if (!branchRootPage) {
            return navToLevels()
        }
        // nav to next root page
        const nextRootPageNum = branchRootPage.num + 1
        const nextRootPage = await fetchNumPage(nextRootPageNum)
        return routeNav(nextRootPage)
    }

    async function navNext() {
        logger(debug)

        if (!cachedPage.value) {
            logger(debug, 'cached page undefined')
            return navToLevels() // Current page must be set already
        }

        if (isBranchPage.value) {
            return navForwardBranchPage()
        }

        const isPageScorable = cachedPage.value.passingScore !== undefined

        // nav to fail/pass branch
        if (isPageScorable) {
            const { getQuizScore } = useQuiz()
            const passingScore = cachedPage.value.passingScore as number
            const passedQuiz = getQuizScore() >= passingScore
            const branchPage = await fetchFirstBranchPage(passedQuiz)
            if (branchPage) {
                return routeNav(branchPage)
            }
        }

        // nav to next root page
        const nextPageNum = cachedPage.value.num + 1
        const nextPage = await fetchNumPage(nextPageNum)
        return routeNav(nextPage)
    }

    async function navBackBranchPage() {
        logger(debug)

        if (!cachedPage.value) {
            logger(debug, 'cached page undefined')
            // Current page must be set already
            return navToLevels()
        }
        const branchRoot = cachedPage.value.branchRoot as string
        const branchNum = cachedPage.value.branchNum as number
        // nav to branch root if page is first in branch
        if (cachedPage.value.num === 1) {
            const branchRootPage = await fetchPage(branchRoot)
            return routeNav(branchRootPage)
        }
        const prevBranchPageNum = cachedPage.value.num - 1
        const prevBranchPage = await fetchBranchNumPage(
            prevBranchPageNum,
            branchRoot,
            branchNum
        )
        return routeNav(prevBranchPage)
    }

    async function navPrevious() {
        logger(debug)
        if (
            !cachedPage.value ||
            (!isBranchPage.value && cachedPage.value.num == 1)
        ) {
            return navToLevels()
        }

        if (isBranchPage.value) {
            return navBackBranchPage()
        }

        const prevPageNum = cachedPage.value.num - 1
        const prevPage = await fetchNumPage(prevPageNum)
        const isPrevPageScorable = prevPage?.passingScore !== undefined

        if (isPrevPageScorable) {
            if (levelId === undefined) throw 'No level specified'
            const { getQuizScore, fetchQuiz } = useQuiz(levelId, prevPage.id)
            const prevQuiz = await fetchQuiz()
            const passingScore = prevPage.passingScore as number
            const passedQuiz = getQuizScore(prevQuiz) >= passingScore
            const finalBranchPage = await fetchFinalBranchPage({
                branchRoot: prevPage.id,
                branchNum: passedQuiz ? 1 : 2,
            })
            if (finalBranchPage) return routeNav(finalBranchPage)
        }

        return routeNav(prevPage)
    }

    // Adding / Updating

    async function addNewPage(num: number) {
        logger(debug)
        if (viewer.value?.id === undefined) throw 'Viewer not initialized'
        if (pagesCollection === undefined) throw 'No level specified'

        const batch = firestore.batch()

        // Update affected pages' num fields
        await shiftDocNums(batch, pagesCollection, {
            shift: 1,
            fromNum: num,
            inBranch: false,
        })

        const newPageDoc = pagesCollection.doc()
        batch.set(newPageDoc, {
            title: '',
            num,
            inBranch: false,
            createdAt: serverTimestamp(),
            updatedBy: viewer.value.id,
        })

        await batch.commit()

        return navToPage(newPageDoc.id)
    }

    async function addNewBranchPage(newBranchPage: {
        title: string
        num: number
        branchRoot: string
        inPassBranch: boolean
        branchNum: number
    }) {
        logger(debug, newBranchPage)
        if (viewer.value?.id === undefined) throw 'Viewer not initialized'
        if (pagesCollection === undefined) throw 'No level specified'

        const { num, branchRoot, branchNum } = newBranchPage

        const batch = firestore.batch()

        // Update affected pages' num fields
        await shiftDocNums(batch, pagesCollection, {
            shift: 1,
            fromNum: num,
            inBranch: true,
            branchRoot: branchRoot,
            branchNum: branchNum,
        })

        const newBranchPageDoc = pagesCollection.doc()
        batch.set(newBranchPageDoc, {
            ...newBranchPage,
            inBranch: true,
            createdAt: serverTimestamp(),
            updatedBy: viewer.value.id,
        })

        await batch.commit()

        navToPage(newBranchPageDoc.id)

        return newBranchPageDoc.id
    }

    async function deleteBranchPage(page: Page) {
        logger(debug)
        if (pagesCollection === undefined) throw 'No level specified'

        if (!isBranchPage.value) return Promise.resolve()

        const { batchUpdateElements } = useLevelPageElements(levelId, page.id)
        const { batchUpdateQuiz } = useQuiz(levelId, page.id)

        let displayPage: Page | undefined
        const branchRoot = cachedPage.value?.branchRoot as string
        const branchNum = cachedPage.value?.branchNum as number
        if (page.num === 1) {
            const nextBranchPageNum = (cachedPage.value?.num as number) + 1
            displayPage = await fetchBranchNumPage(
                nextBranchPageNum,
                branchRoot,
                branchNum
            )
        } else {
            const prevBranchPageNum = (cachedPage.value?.num as number) - 1
            displayPage = await fetchBranchNumPage(
                prevBranchPageNum,
                branchRoot,
                branchNum
            )
        }

        if (displayPage === undefined) {
            displayPage = await fetchPage(branchRoot)
        }

        const batch = firestore.batch()

        await shiftDocNums(batch, pagesCollection, {
            shift: -1,
            fromNum: page.num,
            inBranch: true,
            branchRoot,
            branchNum,
        })

        // Delete elements and questions
        batchUpdateElements([], [], batch)
        batchUpdateQuiz([], batch)

        const pageToDelete = pagesCollection.doc(page.id)
        batch.delete(pageToDelete)

        await batch.commit()

        return routeNav(displayPage)
    }

    async function deletePagesInBranch(
        inPassBranch: boolean,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, inPassBranch)
        if (pagesCollection === undefined) throw 'No level specified'

        const branchPage = inPassBranch
            ? passBranchInitialPage.value
            : failBranchInitialPage.value

        let currPage = branchPage ?? (await fetchFirstBranchPage(inPassBranch))
        while (currPage !== undefined) {
            const { batchUpdateElements } = useLevelPageElements(
                levelId,
                currPage.id
            )
            const { batchUpdateQuiz } = useQuiz(levelId, currPage.id)

            // Delete elements and questions
            batchUpdateElements([], [], batch)
            batchUpdateQuiz([], batch)

            const pageToDelete = pagesCollection.doc(currPage.id)
            batch.delete(pageToDelete)
            currPage = await fetchBranchNumPage(
                currPage.num + 1,
                currPage.branchRoot as string,
                currPage.branchNum as number
            )
        }

        inPassBranch
            ? (passBranchInitialPage.value = undefined)
            : (failBranchInitialPage.value = undefined)
    }

    async function deleteAllBranchPages(batch: FirestoreWriteBatch) {
        logger(debug)
        await Promise.all([
            deletePagesInBranch(true, batch),
            deletePagesInBranch(false, batch),
        ])
    }

    async function deletePage(page: Page) {
        logger(debug)
        if (viewer.value?.id === undefined) throw 'Viewer not initialized'
        if (pagesCollection === undefined) throw 'No level specified'

        const { batchUpdateElements } = useLevelPageElements(levelId, page.id)
        const { batchUpdateFeedbackMedia } = useLevelPageQuizMedia(
            levelId,
            page.id
        )
        const { batchUpdateQuiz } = useQuiz(levelId, page.id)

        // Find page to display if deletion is successful:
        // If deleting first page, display proceeding page.
        // Otherwise, display preceeding page.
        let displayPage: Page | undefined
        if (page.num === 1) displayPage = await fetchNumPage(page.num + 1)
        else displayPage = await fetchNumPage(page.num - 1)

        if (displayPage === undefined) throw 'Cannot delete only page in level'

        const batch = firestore.batch()

        await deleteAllBranchPages(batch)

        // Update affected pages' num fields
        await shiftDocNums(batch, pagesCollection, {
            shift: -1,
            fromNum: page.num,
            inBranch: false,
        })

        // Delete elements and questions
        batchUpdateElements([], [], batch)
        batchUpdateFeedbackMedia({}, [], batch)
        batchUpdateQuiz([], batch)

        const pageToDelete = pagesCollection.doc(page.id)
        batch.delete(pageToDelete)

        await batch.commit()

        // Nav and update cached page
        return routeNav(displayPage)
    }

    function batchUpdatePage(
        updatedPage: Partial<Page>,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, updatedPage)
        if (viewer.value?.id === undefined) throw 'Viewer not initialized'
        if (cachedPage.value === undefined) throw 'Page is undefined'
        if (pagesCollection === undefined) throw 'No level specified'

        batch.update(pagesCollection.doc(updatedPage.id), {
            ...cleanObjForFirestore('update', updatedPage, {
                preserveFields: ['title'],
                markAllUnwantedFieldsForDeletion: true,
            }),
            updatedAt: serverTimestamp(),
            updatedBy: viewer.value.id,
        })

        Object.assign(cachedPage.value, updatedPage)
    }

    function deinitLevelPage() {
        logger(debug)
        cachedPage.value = undefined
        branchRootPage.value = undefined
        failBranchInitialPage.value = undefined
        passBranchInitialPage.value = undefined
    }

    // Watcher

    watch(
        () => cachedPage.value?.id,
        () => {
            failBranchInitialPage.value = undefined
            passBranchInitialPage.value = undefined
        }
    )

    return {
        // State
        page: readonly(cachedPage),
        branchRootPage: readonly(branchRootPage),
        passBranchInitialPage: readonly(passBranchInitialPage),
        failBranchInitialPage: readonly(failBranchInitialPage),
        isBranchPage,
        isPageLocked,

        // Functions
        addNewPage,
        addNewBranchPage,
        batchUpdatePage,
        deinitLevelPage,
        deleteBranchPage,
        deletePage,
        deleteAllBranchPages,
        fetchFinalPage,
        fetchPage,
        fetchNumPage,
        fetchPreviousRootPages,
        getLevelPages,
        getProgressedPageCount,
        getTotalBranchPageCount,
        getTotalRootPageCount,
        loadBranchPages,
        loadFirstPage,
        loadPage,
        loadBranchRootPage,
        navPrevious,
        navNext,
        navToLevels,
        routeNav,
        setLevel,
    }
}
