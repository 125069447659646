import { functions } from '@/firebase-config'

import { logger } from '@/utils/debug'

import { UserRole } from '@/models/user'

const debug = false

export type AuthUserData = {
    id: string
    password: string
    roles: UserRole[]
    createdBy: string
}

export async function updateUserNotificationBadge(userId: string) {
    logger(debug, userId)
    return functions.httpsCallable('updateUserNotificationBadge')({ userId })
}

export async function convertCallRecording({
    compositionId,
    chatId,
    callRoomId,
}: {
    chatId: string
    callRoomId: string
    compositionId: string
}) {
    logger(debug, chatId, callRoomId, compositionId)
    return functions.httpsCallable('convertCallRecording')({
        chatId,
        callRoomId,
        compositionId,
    })
}
export async function convertVoiceResponse(
    voiceUrl: string,
    userId: string,
    voiceResponseId: string,
    duration: number,
    questionAudioByteArray?: number[]
) {
    logger(debug, voiceUrl, userId)
    return functions.httpsCallable('convertVoiceResponse')({
        voiceUrl,
        userId,
        voiceResponseId,
        duration,
        questionAudioByteArray,
    })
}

/**
 * Joins or creates a Twilio room
 *
 * Joins or creates a call room identified by its name  and generates an access token for a participant identified by their name (`participantName`).
 * The access token can be used to authenticate and join the  call session as the specified participant.
 *
 * @param room - The name of the  call room to join or create.
 * @param identity - The name of the participant joining the  call.
 * @param kind The kind of the room to join or create - either audio or video.
 * @returns  An access token string for the specified participant to join the  call.
 */
export async function createCallAccessToken(
    room: string,
    identity: string,
    kind: 'audio' | 'video'
) {
    logger(debug, identity, room, kind)
    return functions.httpsCallable('createCallAccessToken')({
        identity,
        room,
        kind,
    })
}

export async function createAuthUser(userData: AuthUserData) {
    logger(debug, userData)
    return functions.httpsCallable('createAuthUser')(userData)
}

/**
 *
 * Downloads a completed video composition from Twilio's server.
 * The video composition combines the audio and video streams of all participants in a completed  call room into a single video file.
 *
 * @param  url- The URL of the completed video composition to download.
 * @returns A buffer containing the downloaded video composition.
 *
 */
export async function downloadCompositionCall(compositionId: string) {
    logger(debug, compositionId)
    return functions.httpsCallable('downloadCompositionCall')({
        compositionId,
    })
}

export async function listCallRecordings(roomSid: string) {
    logger(debug, roomSid)
    return functions.httpsCallable('listCallRecordings')({
        roomSid,
    })
}

export async function syncCustomClaims() {
    logger(debug)
    return functions.httpsCallable('syncCustomClaims')()
}

/**
 *
 * Updates the recording rules for a call room identified by its unique identifier (`roomSid`).
 * The recording rules specify which participants' audio and video streams should be recorded during the call.
 *
 * @param roomSid - The unique identifier of the call room to update.
 * @param rules - An array of recording rules to apply to the call room.
 *
 */
export async function updateCallRecordingRules(
    roomSid: string,
    // eslint-disable-next-line
    rules: any[]
) {
    logger(debug, roomSid, rules)
    return functions.httpsCallable('updateCallRecordingRules')({
        roomSid,
        rules,
    })
}

export async function updateLogSummaries({
    callerId,
    userIds,
}: {
    callerId: string
    userIds: string[]
}) {
    return functions.httpsCallable('updateLogSummaries')({ callerId, userIds })
}
